// import libraries
import React, { useState } from "react"

import styled, { ThemeProvider } from "styled-components"

// import styles and custom react hooks
import theme from "../styles/theme"
import GlobalStyle from "../styles/globalStyle"
import { HeaderOverWrappedProvider } from "../hooks/HeaderOverWrappedContext"

// import components
import Header from "./Header"
import Footer from "./Footer"

const StyledLayoutWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  font-size: 15px;
  position: relative;
  z-index: 0;
  main {
    overflow: hidden;
    background: #131313;
    color: white;
    margin-top: 60px;
    position: relative;
    z-index: 0;
  }
`

const Layout = ({ children }) => {
  // const data = useStaticQuery(query)
  const [isActive, useIsActive] = useState(false)
  return (
    <StyledLayoutWrapper>
      <GlobalStyle isOff={isActive} />
      <ThemeProvider theme={theme}>
        <HeaderOverWrappedProvider>
          <Header setOverflowHidden={useIsActive} />
          <main>{children}</main>
          <Footer />
        </HeaderOverWrappedProvider>
      </ThemeProvider>
    </StyledLayoutWrapper>
  )
}

// export const query = graphql`
//   {
//     zoo: strapiIndexMemo {
//       backBlack {
//         childImageSharp {
//           fluid {
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }
//       }
//     }
//   }
// `

export default Layout
