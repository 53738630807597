import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import BeigeLogo from "../images/logoBrown.svg"
import { Link } from "gatsby"

const StyledMenuList = styled.div`
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: -120%;
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  justify-content:center;
  animation: ${props => {
    if (props.isMenuListActive !== null) {
      if (props.isMenuListActive) {
        return `menu-slide-in 0.3s linear 0s forwards`
      }
      return `menu-slide-out 0.3s linear 0s forwards`
    }
  }};
  z-index: 1000;
  @keyframes menu-slide-in {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-120%);
    }
    /* 0% {
      transform: translateX(0);
    }
    70% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(-700px);
    }
    90% {
      transform: translateX(-700px);
    }
    100% {
      transform: translateX(-700px);
    } */
  }
  @keyframes menu-slide-out {
    from {
      transform: translateX(-120%);
    }
    to {
      transform: translateX(0);
    }
  }
  .navList{
    display:flex;
    flex-flow:column nowrap;
    align-items:center;
    justify-content:center;
    color:white;
    margin-bottom:50px;
    a{
      font-size:1.62em;
      margin-bottom:1.62em;
      font-family:'Comfortaa';
    }
  }
  img{
    width:100%;
    ${() => media.tablet(css`
      width:20%;
    `
  )}
  }
`

const MenuList = ({ isMenuListActive }) => {
  return (
    <StyledMenuList isMenuListActive={isMenuListActive}>
      <div className="navList">
        <Link to="/for-first/">welcome to us!</Link>
        <Link to="/aboutus/">concept</Link>
        <Link to="/our-menues/">menu</Link>
        <Link to="/inquiry/">contact</Link>
        <Link to="/release/">blog</Link>
      </div>
      <img src={BeigeLogo} />
    </StyledMenuList>
  )

  // return <></>
}

export default MenuList
