import { css } from "styled-components"

export const media = {
  tablet: styles => css`
    @media only screen and (min-width:600px){
      ${styles}
    }
    `,
  sp: styles => css`
    @media only screen and(min-width: 1100px){
      ${styles}
    }
  `
}
