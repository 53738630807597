import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import MenuList from "./MenuList"
import HeaderOverWrapper from "./HeaderOverWrapper"
import { HeaderOverWrappedContext } from "../hooks/HeaderOverWrappedContext"
import corpLogo from "../images/markWhite.svg"

// export const query = graphql`
//   {}
// `

const StyledWrapper = styled.div`
  height: 60px;
  width: 100%;
  padding: 0 5%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  background: black;
`

const StyledTopIcon = styled.div`
  img {
    height: 45px;
    width: auto;
  }
`
const StyledNavBtn = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  z-index: 1100;
  cursor: pointer;
  span {
    font-size: 12px;
    color: white;
  }

  #btnDesign {
    width: 50px;
    height: 18px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content:  ${({ isActive }) => (isActive ? "center" : "space-around")};
    position: relative;
    span {
      background: ${({ isActive }) => (isActive ? "#c0a77f" : "white")};
      height: 2px;
      border-radius: 3px;
      width: 60%;
      transition:.8s;
      position:${({ isActive }) => (isActive ? "absolute" : "relative")};
      &.btnTop{
        transform: ${({ isActive }) => (isActive ? "rotate(225deg)" : "rotate(0)")};
      }
      &.btnBottom{
        transform: ${({ isActive }) => (isActive ? "rotate(-225deg)" : "rotate(0)")};
      }
    }
  }
`

const Header = (props) => {
  const [isMenuListActive, setIsMenuListActive] = useState(null)
  const [state] = useContext(HeaderOverWrappedContext)

  return (
    <header>
      <StyledWrapper>
        <StyledTopIcon>
          <Link to="/">
            <img src={corpLogo} alt="アリンタロイヤルリゾートロゴ" />
          </Link>
        </StyledTopIcon>
        <StyledNavBtn
          onClick={
            () => {
              setIsMenuListActive(!isMenuListActive)
              props.setOverflowHidden(!isMenuListActive)
            }
          }
          isActive={isMenuListActive}
        >
          <span id="btnDesign">
            <span className="btnTop"></span>
            <span className="btnBottom"></span>
          </span>
        </StyledNavBtn>
        {state.isDisplay && <HeaderOverWrapper />}
        <MenuList isMenuListActive={isMenuListActive} />
      </StyledWrapper>
    </header>
  )
}

export default Header
