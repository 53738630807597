/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


function SEO({ description, imageUrl, type, pageUrl, title, jsonld = null }) {
  const data = useStaticQuery(query)
  const metaDescription = description || data.strapiSeo.organizeName
  const defaultTitle = data.strapiSeo.organizeName

  // Default Website Schema
  let schemaOrgJSONLD = [
    {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "アリンタロイヤルリゾート",
      "hasMap": "https://www.google.com/maps/place/%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%BF%E3%83%AD%E3%82%A4%E3%83%A4%E3%83%AB%E3%83%AA%E3%82%BE%E3%83%BC%E3%83%88/@35.763116,139.850286,16z/data=!4m5!3m4!1s0x0:0x256ab199e23acf14!8m2!3d35.7631157!4d139.8502855?hl=ja",
      "hasMap": "https://www.google.com/maps/dir//%E3%80%92125-0061+%E6%9D%B1%E4%BA%AC%E9%83%BD%E8%91%9B%E9%A3%BE%E5%8C%BA%E4%BA%80%E6%9C%89%EF%BC%93%E4%B8%81%E7%9B%AE%EF%BC%93%EF%BC%97%E2%88%92%EF%BC%93%EF%BC%91+%E3%82%A2%E3%83%93%E3%82%BF%E3%82%B7%E3%82%AA%E3%83%B3%E6%A3%AE+1F+%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%BF%E3%83%AD%E3%82%A4%E3%83%A4%E3%83%AB%E3%83%AA%E3%82%BE%E3%83%BC%E3%83%88/@35.763116,139.850286,16z/data=!3m1!5s0x60188f8688a314e9:0x90471b3a2877cd72!4m9!4m8!1m0!1m5!1m1!1s0x60188fa6b99e6cf1:0x256ab199e23acf14!2m2!1d139.8502855!2d35.7631157!3e0?hl=ja",
      "description": data.strapiSeo.pagesSeo[0].pageDescript,
      "url": "https://aleenta-rr.jp/",
      "telephone": "03-6662-7281",
      "priceRange": "¥4,000～¥17,000",
      "currenciesAccepted": "JPY",
      "paymentAccepted": "現金,クレジットカード",
      "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00-20:00",
      "address": {
        "@type": "PostalAddress",
        "postalCode": "125-0061",
        "addressRegion": "東京都",
        "addressLocality": "葛飾区",
        "streetAddress": "亀有3-37-31 アビタシオン森1F",
        "hasMap": "https://www.google.com/maps/place/%E3%82%A2%E3%83%AA%E3%83%B3%E3%82%BF%E3%83%AD%E3%82%A4%E3%83%A4%E3%83%AB%E3%83%AA%E3%82%BE%E3%83%BC%E3%83%88/@35.763116,139.850286,16z/data=!4m5!3m4!1s0x0:0x256ab199e23acf14!8m2!3d35.7631157!4d139.8502855?hl=ja",
        "addressCountry": {
          "@type": "Country",
          "name": "JP"
        }
      },
      "image": [
        {
          "@type": "ImageObject",
          "url": data.strapiSeo.pagesSeo[0].pageMedia.absolutePath
        }
      ]
    },
  ]

  if (jsonld) {
    // add to default schemaOrgJSONLD variable
    // const addedSchemaOrgJSONLD = [
    //   {
    //     "@context": "http://schema.org",
    //     "@type": "WebSite",
    //     url: site.siteUrl,
    //     name: defaultTitle,
    //   },
    // ]
    if (Array.isArray(jsonld)) {
      schemaOrgJSONLD = [...schemaOrgJSONLD, ...jsonld]
    } else if (typeof jsonld === "object") {
      schemaOrgJSONLD.push(jsonld)
    }
  }

  return (
    <Helmet>
      <html lang='ja' prefix='og:http://ogp.me/n2#/' />
      <title>{title || defaultTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="image" content={imageUrl || data.strapiSeo.logoImage.absolutePath} />
      <meta name="apple-mobile-web-app-title" content={data.strapiSeo.organizeName} />
      <meta name="application-name" content={data.strapiSeo.organizeName} />

      <link rel="canonical" href={pageUrl} />

      <meta propery="og:image" content={imageUrl || data.strapiSeo.logoImage.absolutePath} />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type || "website"} />
      <meta property="og:locale" content="ja_JP" />
      <meta name='twitter:card' content='summary' />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `ja`,
  title: "アリンタロイヤルリゾート",
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export const query = graphql`
  {
    strapiSeo {
      organizeName
      personName
      logoImage {
        absolutePath
      }
      pagesSeo {
        pageUrl
        pageTitle
        pageDescript
        pageMedia {
          absolutePath
        }
      }
    }
  }
`

export default SEO
