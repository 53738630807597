import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import corpLogoOvewrapped from "../images/gatsby-icon.png"

const StyledHeaderOverWrapper = styled.div`
  position: absolute;
  background: white;
  box-shadow: 0px 1px 0px#dadada;
  width: 100%;
  height: 100%;
  top: -60px;
  left: 0;
  animation: header-move-in 0.4s linear 0s forwards;
  z-index: 980;
  @keyframes header-move-in {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(60px);
    }
    50% {
      transform: translateY(60px);
    }
    100% {
      transform: translateY(60px);
    }
  }
  padding: 0 5%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  a img {
    height: 45px;
  }
`

const HeaderOverWrapper = () => (
  <StyledHeaderOverWrapper>
    <Link to="/">
      <img src={corpLogoOvewrapped} alt="" />
    </Link>
  </StyledHeaderOverWrapper>
)

export default HeaderOverWrapper
