import React from "react"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { Link } from "gatsby"
import Image from "gatsby-image"
import FooterLogoImage from "../images/logo-sample.png"
import { BiCopyright } from "react-icons/bi"
// import { FaExternalLinkAlt } from "react-icons/fa"
import { GrYoutube, GrFacebook } from "react-icons/gr"
import { AiFillInstagram } from "react-icons/ai"
import BackImage from "../images/backgroundBlack.png"
import LogoImage from "../images/markWhite.svg"
import { IconContext } from "react-icons"

const IconIg = () => (
  <IconContext.Provider value={{ size: "20px", color: "white" }}>
    <AiFillInstagram />
  </IconContext.Provider>
)
const IconFb = () => (
  <IconContext.Provider value={{ size: "20px", color: "white" }}>
    <GrFacebook />
  </IconContext.Provider>
)

const StyledFooterWrapper = styled.div`
  background: white;
  overflow: hidden;
  position: relative;
  .footBackImg {
    background: rgba(0, 0, 0, 0.98);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    z-index: 1;
    color: white;
    padding: 50px 5% 20px;
    .footerCopy p {
      color: white;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
    }
    .footerLogo {
      width: 30%;
      img {
        width: 100%;
      }
      ${() =>
    media.tablet(css`
          width: 20%;
        `)}
    }
    nav {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 30px 0 20px;
      a {
        font-size: 1.32em;
        margin: 0.32em 0;
      }
    }
    .snss {
      padding: 30px 0;
      width: 80%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      a {
        background: rgba(255, 255, 255, 0.3);
        padding: 10px;
        margin: 0 2px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

const Footer = () => {
  return (
    <StyledFooterWrapper>
      <div className="footBackImg">
        <img src={BackImage} />
      </div>
      <footer id="footer">
        <div className="footerLogo">
          <img src={LogoImage} />
        </div>
        <nav>
          <Link to="/for-first/">welcome to Aleenta!</Link>
          <Link to="/our-menues/">menu</Link>
          <Link to="/aboutus/">concept</Link>
          <Link to="/release/">news</Link>
          <Link to="/inquiry/">contact</Link>
        </nav>
        <div className="snss">
          <a
            href="https://www.instagram.com/aleenta2020/?hl=ja"
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconIg />
          </a>
          <a
            href="https://www.facebook.com/aleentaroyalresort"
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconFb />
          </a>
        </div>
        {/* Copy right section */}
        <div className="footerCopy">
          <p>
            <BiCopyright />
            2020 Aleenta Royal Resort
          </p>
        </div>
      </footer>
    </StyledFooterWrapper>
  )
}

export default Footer
