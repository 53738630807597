const theme = {
  colors: {
    primary: {
      red: `#980010`,
      brown: `#5d3e26`,
      beige: `#c0a77f`
    },
    light: {
      brown: `#E4D8B9`,
    },
    dark: {
      gray: `#C4C4C4`,
    },
  },
}

export default theme
